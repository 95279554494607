.container-info{
    /* border: 1px solid black; */
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--textoSpan)
}
.info-titulo{
    /* border: 1px solid black; */
    width: 120px;
    padding-left: 8px;
}
.info-texto{
    /* border: 1px solid black; */
    width: 320px;
    border-radius: 3px;
    font-size: 20px;
    background: var(--quaseBranco);
    padding: 5px;
}
.info-input-dis {
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 4px;
    opacity:80%
}
.info-input{
    border: none;
}
.container-info input{
    width: 320px;
    border-radius: 3px;
    font-size: 20px;
    background: var(--quaseBranco);
    padding: 5px;
}
.icons-info:hover{
    cursor: pointer;
    margin-left: 5px;
}
.icons-info{
    margin-left: 5px;
}