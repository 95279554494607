.input-cabecalho {
    display: inline-block;
    width: 80%;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0px 10px 5px 0px;
    font-size: 16px;
    font-family: var(--fonteTextos);
    color: #666;
}
.input-cabecalho-menor {
    display: inline-block;
    width: 150px;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0px 10px 5px 0px;
    font-size: 16px;
    font-family: var(--fonteTextos);
    color: #666;
}
.cabecalho-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.cabecalho-container button{
    margin-top: 0px;
    background: var(--laranja);
    font-family: var(--fonteObjetos);
    font-size: 18px;
    font-weight: 700;
    color: var(--quaseBranco);
}