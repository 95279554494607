.corpo-laudo-box {
    position:sticky;
    overflow-y:auto;
    top:85px;
    width: 98%;
    max-height: 90vh;
    margin: 5px;
    padding: 10px 5px 5px 5px;
    background: var(--fundoArticle);
    border: var(--bordaArticleM);
    border-radius: var(--bordaArticleR);

}
/* .corpo-laudo-box .titulo {
    text-align: center;
    color: var(--textoArticle);
    margin-bottom: 15px;
} */
.corpo-laudo-box .subtitulo {
    text-align: center;
    color: var(--textoArticle);
    margin-bottom: 15px;
    padding: 7px 0px;
}
.corpo-laudo-box #tit-opiniao {
    text-align: left;
    color: var(--textoArticle);
    margin-bottom: 5px;
    padding: 7px 0px;
}
.corpo-laudo-box p{
    padding: 2px;
    text-align: justify;
    color: var(--textoArticle);
    margin-bottom: 10px;
    /* background: var(--fundoTexto) */
}
.negrito {
    font-weight: 700;
}
.corpo-laudo-box span {
    display: inline-block;
    margin-bottom: 10px;
    color: var(--textoArticle);
}
.corpo-laudo-box .actions {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    margin-top:7px;
}
.corpo-laudo-box .rodape {
    text-align: right;
}
/* .corpo-laudo-box .pItalico {
    font-style: italic;
    font-size: 12px;
}
.corpo-laudo-box .pTabela {
    font-style: italic;
    font-size: 12px;
    padding: 0;
}
 */