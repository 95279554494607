
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing:border-box;
  font-family: 'Red Hat Text', sans-serif;
}



body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --laranja : #da552f;
  --laranjaB : #da552f;
  --quaseBranco : #fff;
  --quaseAmarelo: #da5f;
  --fundoArticle: #F8F6F6;
  /* --bordaArticle: #ddd; */
  --textoArticle: #666;
  --fundoTexto: #fff;
  --textoSpan: #666;
  --bordaDiv: orange;
  --fundoDiv: hsla(44, 100%, 75%, 0.20);
  --textoPrincipal: #da552f;
  --bordaArticleM: 1px solid #ddd;
  --bordaArticleR: 5px;
  --linhaP: #163675;
  --fundoBarraLateral: hsla(249, 17%, 92%, 0.281);
  --fundoPrincipal: linear-gradient(to right, #00467F, #00172d);
  --fundoTitulo: none;
  
  --fonteObjetos: 'Raleway',sans-serif;
  --fonteTextos: 'Red Hat Text', sans-serif;
  }
