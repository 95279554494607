.input-container {
    margin: 0px 10px 5px 0px;
    font-size: 16px;
    display: flex;
        
}
.input-container span{
    align-self: center;
    /* color: #666; */
    color: var(--textoArticle);

}
.switch {
    align-self: center;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    display: inline-block;
    width: 41px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: -1;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--laranjaB);
    /* background-color: #4547a8; */
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #4547a8;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    z-index: 1;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }