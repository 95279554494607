.login-barra-sup {
    position:fixed;
    width: 100% ;
    height: 60px;
    background-color: var(--laranja);
    display: flex;
    align-items: center;
    justify-content: center;
}
.barra-sup-texto{
    font-size: 24px; 
    font-weight: bold; 
    color: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    letter-spacing: 2px;
}
.login-corpo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:url(../../images/imagefundo.jpg);
    
    min-height: 100vh;
}
.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    min-height: 500px;
    border-radius: 5px;
    background:hsla(22, 14%, 89%, 0.21);    
    padding: 40px;
}
.login-form img{
    width: 140px;
    margin-bottom: 10px;
}
.login-form input {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 25px;
    color: #777;
    font-size: 22px;
    font-family: var(--fonteTextos);
    border-radius: 5px;
    border: 1px solid #ddd;
}
.p-visivel {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 10px;
    height: 45px;
    width: 100%;
    text-align: center;
}
.p-invisivel {
    color: transparent;
    margin-bottom: 10px;
    border:none;
    height: 45px;
    width: 45px;
}
.login-but-cont{
    /* border: 1px solid black; */
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.barra-container{
    width: 100%;
    height: 5px;
    margin-bottom: 20px;
}

