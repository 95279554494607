.botao-branco-menor {
    background: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    font-size: 18px;
    font-weight: 700;
    color: var(--laranja);
    border: 0;
    padding: 5px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    min-width: 100%;
}
.botao-simples-branco {
    background: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--laranja);
    border: 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    min-width: 100px;
}
.botao-simples-laranja {
    background: var(--laranja);
    font-family: var(--fonteObjetos);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--quaseBranco);
    border: 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}