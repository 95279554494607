.div-group-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-linf-tipico{
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.bloco-label-input-label{
    height: 30px;
}
.bloco-info{
    margin-left: 20px;
}
.bloco-info .select-laudo-box {
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;
    padding: 1px;
    margin-bottom: 5px;
    color: var(--textoSpan);
    font-size: 16px;
    background: var(--fundoTexto);
    line-height: 24px;
    margin-top: 3px;
}