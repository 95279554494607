.principal-main-container {
    
}
.principal-barra-superior {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 0px 35px;
    background: var(--laranja);
    font-size: 22px; 
    font-weight: bold; 
    color: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    letter-spacing: 2px;

}

.botoes-container {
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.principal-corpo {
    min-height: 100vh;
    background: var(--fundoPrincipal); 
    display: flex;
    flex-direction:row

}
.corpo-barra-lateral{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;
    padding-top: 30px;
    width: 150px;
    height: 100vh;
    padding: 0px 10px;
    background: var(--fundoBarraLateral);
}
.corpo-modulo {
    margin: 85px 0px 25px 185px;
    padding: 5px;
    border: 1px solid black;
    width: 800px;
    border: 1px solid var(--bordaDiv);
    border-radius: var(--bordaArticleR);
    background-color: var(--fundoDiv)
}
.corpo-laudo-view{
    margin: 85px 25px 25px 25px;
    border: 1px solid black;
    width: 600px;
    border: 1px solid var(--bordaDiv);
    border-radius: var(--bordaArticleR);
    background-color: var(--fundoDiv)
}
/* .corpo-laudo-box{
    position: fixed;
    width: 590px;
} */
.corpo-modulo .box-modelo {
    background: var(--fundoArticle);
    border: var(--bordaArticleM);
    border-radius: var(--bordaArticleR);
    padding: 2px 5px;
    margin-bottom: 10px;
}
.corpo-modulo .box-modelo-titulo {
    color: var(--textoArticle);
    font-family: var(--fonteTextos);
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 4px;
    padding-top: 3px;
    text-align: center;
}
.input-radio{
    /* display: inline-block; */
    width: 5%;
    margin: 5px 3px 0px 0px;
}
.label-radio{
    display: inline-block;
    width: 90%;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0px 10px 5px 0px;
    color:#7E7878;
    background-color: white; 
    font-family: var(--fonteTextos);

}
.input-radio:checked + .label-radio{
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 5px 0 rgb(0 0 0 / 19%);
    font-weight: 600;
    color: #333;
}
.input-medida-maior{
    /* margin-left: 3px; */
    margin-right: 7px;
    width: 100px;
    border: 2px solid rgb(202, 201, 201);
    border-radius: 5px;
    /* margin-bottom: 7px; */
    /* padding: 3px 3px; */
    padding-left: 8px;
    height: 90%;
    -moz-appearance:textfield;
}
.input-medida{
    /* margin-left: 3px; */
    margin-right: 7px;
    width: 70px;
    border: 2px solid rgb(202, 201, 201);
    border-radius: 5px;
    /* margin-bottom: 7px; */
    /* padding: 3px 3px; */
    padding-left: 10px;
    height: 90%;
    -moz-appearance:textfield;
}
.label-medida{
    /* align-self: center; */
    margin-right: 5px;
    color:var(--textoArticle);
    height: 80%;
}
.box-result span {
    font-size: 16px;
    padding-left:5px;
    color: var(--textoSpan);
    
}
.p-titulo-radios{
    margin-top: 5px;
    font-weight: 600;
    width: 100%;
    border-top: 1px solid var(--linhaP); 
}