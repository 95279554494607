.div-select .select-laudo-box {
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;
    padding: 1px;
    margin-bottom: 5px;
    color: var(--textoSpan);
    font-size: 16px;
    background: var(--fundoTexto);
    line-height: 24px;
    margin-top: 3px;
}
.box-result {
    background: var(--fundoTexto);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}
