
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing:border-box;
  font-family: 'Red Hat Text', sans-serif;
}



body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --laranja : #da552f;
  --laranjaB : #da552f;
  --quaseBranco : #fff;
  --quaseAmarelo: #da5f;
  --fundoArticle: #F8F6F6;
  /* --bordaArticle: #ddd; */
  --textoArticle: #666;
  --fundoTexto: #fff;
  --textoSpan: #666;
  --bordaDiv: orange;
  --fundoDiv: hsla(44, 100%, 75%, 0.20);
  --textoPrincipal: #da552f;
  --bordaArticleM: 1px solid #ddd;
  --bordaArticleR: 5px;
  --linhaP: #163675;
  --fundoBarraLateral: hsla(249, 17%, 92%, 0.281);
  --fundoPrincipal: linear-gradient(to right, #00467F, #00172d);
  --fundoTitulo: none;
  
  --fonteObjetos: 'Raleway',sans-serif;
  --fonteTextos: 'Red Hat Text', sans-serif;
  }

.login-barra-sup {
    position:fixed;
    width: 100% ;
    height: 60px;
    background-color: var(--laranja);
    display: flex;
    align-items: center;
    justify-content: center;
}
.barra-sup-texto{
    font-size: 24px; 
    font-weight: bold; 
    color: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    letter-spacing: 2px;
}
.login-corpo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:url(/static/media/imagefundo.190f94e8.jpg);
    
    min-height: 100vh;
}
.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    min-height: 500px;
    border-radius: 5px;
    background:hsla(22, 14%, 89%, 0.21);    
    padding: 40px;
}
.login-form img{
    width: 140px;
    margin-bottom: 10px;
}
.login-form input {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 25px;
    color: #777;
    font-size: 22px;
    font-family: var(--fonteTextos);
    border-radius: 5px;
    border: 1px solid #ddd;
}
.p-visivel {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 10px;
    height: 45px;
    width: 100%;
    text-align: center;
}
.p-invisivel {
    color: transparent;
    margin-bottom: 10px;
    border:none;
    height: 45px;
    width: 45px;
}
.login-but-cont{
    /* border: 1px solid black; */
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.barra-container{
    width: 100%;
    height: 5px;
    margin-bottom: 20px;
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */
.principal-main-container {
    
}
.principal-barra-superior {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 0px 35px;
    background: var(--laranja);
    font-size: 22px; 
    font-weight: bold; 
    color: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    letter-spacing: 2px;

}

.botoes-container {
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.principal-corpo {
    min-height: 100vh;
    background: var(--fundoPrincipal); 
    display: flex;
    flex-direction:row

}
.corpo-barra-lateral{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;
    padding-top: 30px;
    width: 150px;
    height: 100vh;
    padding: 0px 10px;
    background: var(--fundoBarraLateral);
}
.corpo-modulo {
    margin: 85px 0px 25px 185px;
    padding: 5px;
    border: 1px solid black;
    width: 800px;
    border: 1px solid var(--bordaDiv);
    border-radius: var(--bordaArticleR);
    background-color: var(--fundoDiv)
}
.corpo-laudo-view{
    margin: 85px 25px 25px 25px;
    border: 1px solid black;
    width: 600px;
    border: 1px solid var(--bordaDiv);
    border-radius: var(--bordaArticleR);
    background-color: var(--fundoDiv)
}
/* .corpo-laudo-box{
    position: fixed;
    width: 590px;
} */
.corpo-modulo .box-modelo {
    background: var(--fundoArticle);
    border: var(--bordaArticleM);
    border-radius: var(--bordaArticleR);
    padding: 2px 5px;
    margin-bottom: 10px;
}
.corpo-modulo .box-modelo-titulo {
    color: var(--textoArticle);
    font-family: var(--fonteTextos);
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 4px;
    padding-top: 3px;
    text-align: center;
}
.input-radio{
    /* display: inline-block; */
    width: 5%;
    margin: 5px 3px 0px 0px;
}
.label-radio{
    display: inline-block;
    width: 90%;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0px 10px 5px 0px;
    color:#7E7878;
    background-color: white; 
    font-family: var(--fonteTextos);

}
.input-radio:checked + .label-radio{
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 5px 0 rgb(0 0 0 / 19%);
    font-weight: 600;
    color: #333;
}
.input-medida-maior{
    /* margin-left: 3px; */
    margin-right: 7px;
    width: 100px;
    border: 2px solid rgb(202, 201, 201);
    border-radius: 5px;
    /* margin-bottom: 7px; */
    /* padding: 3px 3px; */
    padding-left: 8px;
    height: 90%;
    -moz-appearance:textfield;
}
.input-medida{
    /* margin-left: 3px; */
    margin-right: 7px;
    width: 70px;
    border: 2px solid rgb(202, 201, 201);
    border-radius: 5px;
    /* margin-bottom: 7px; */
    /* padding: 3px 3px; */
    padding-left: 10px;
    height: 90%;
    -moz-appearance:textfield;
}
.label-medida{
    /* align-self: center; */
    margin-right: 5px;
    color:var(--textoArticle);
    height: 80%;
}
.box-result span {
    font-size: 16px;
    padding-left:5px;
    color: var(--textoSpan);
    
}
.p-titulo-radios{
    margin-top: 5px;
    font-weight: 600;
    width: 100%;
    border-top: 1px solid var(--linhaP); 
}
.corpo-laudo-box {
    position:-webkit-sticky;
    position:sticky;
    overflow-y:auto;
    top:85px;
    width: 98%;
    max-height: 90vh;
    margin: 5px;
    padding: 10px 5px 5px 5px;
    background: var(--fundoArticle);
    border: var(--bordaArticleM);
    border-radius: var(--bordaArticleR);

}
/* .corpo-laudo-box .titulo {
    text-align: center;
    color: var(--textoArticle);
    margin-bottom: 15px;
} */
.corpo-laudo-box .subtitulo {
    text-align: center;
    color: var(--textoArticle);
    margin-bottom: 15px;
    padding: 7px 0px;
}
.corpo-laudo-box #tit-opiniao {
    text-align: left;
    color: var(--textoArticle);
    margin-bottom: 5px;
    padding: 7px 0px;
}
.corpo-laudo-box p{
    padding: 2px;
    text-align: justify;
    color: var(--textoArticle);
    margin-bottom: 10px;
    /* background: var(--fundoTexto) */
}
.negrito {
    font-weight: 700;
}
.corpo-laudo-box span {
    display: inline-block;
    margin-bottom: 10px;
    color: var(--textoArticle);
}
.corpo-laudo-box .actions {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    margin-top:7px;
}
.corpo-laudo-box .rodape {
    text-align: right;
}
/* .corpo-laudo-box .pItalico {
    font-style: italic;
    font-size: 12px;
}
.corpo-laudo-box .pTabela {
    font-style: italic;
    font-size: 12px;
    padding: 0;
}
 */
.botao-branco-menor {
    background: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    font-size: 18px;
    font-weight: 700;
    color: var(--laranja);
    border: 0;
    padding: 5px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    min-width: 100%;
}
.botao-simples-branco {
    background: var(--quaseBranco);
    font-family: var(--fonteObjetos);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--laranja);
    border: 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    min-width: 100px;
}
.botao-simples-laranja {
    background: var(--laranja);
    font-family: var(--fonteObjetos);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--quaseBranco);
    border: 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}
.div-group-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-linf-tipico{
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.bloco-label-input-label{
    height: 30px;
}
.bloco-info{
    margin-left: 20px;
}
.bloco-info .select-laudo-box {
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;
    padding: 1px;
    margin-bottom: 5px;
    color: var(--textoSpan);
    font-size: 16px;
    background: var(--fundoTexto);
    line-height: 24px;
    margin-top: 3px;
}
.input-cabecalho {
    display: inline-block;
    width: 80%;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0px 10px 5px 0px;
    font-size: 16px;
    font-family: var(--fonteTextos);
    color: #666;
}
.input-cabecalho-menor {
    display: inline-block;
    width: 150px;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 0px 10px 5px 0px;
    font-size: 16px;
    font-family: var(--fonteTextos);
    color: #666;
}
.cabecalho-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.cabecalho-container button{
    margin-top: 0px;
    background: var(--laranja);
    font-family: var(--fonteObjetos);
    font-size: 18px;
    font-weight: 700;
    color: var(--quaseBranco);
}
.input-container {
    margin: 0px 10px 5px 0px;
    font-size: 16px;
    display: flex;
        
}
.input-container span{
    align-self: center;
    /* color: #666; */
    color: var(--textoArticle);

}
.switch {
    align-self: center;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    display: inline-block;
    width: 41px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    z-index: -1;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--laranjaB);
    /* background-color: #4547a8; */
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #4547a8;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    z-index: 1;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
    font-size: 16px;
    font-family: var(--fonteTextos);
    color: var(--textoSpan);

}


.div-select .select-laudo-box {
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;
    padding: 1px;
    margin-bottom: 5px;
    color: var(--textoSpan);
    font-size: 16px;
    background: var(--fundoTexto);
    line-height: 24px;
    margin-top: 3px;
}
.box-result {
    background: var(--fundoTexto);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.laudo-3x1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    
}

.container-info{
    /* border: 1px solid black; */
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--textoSpan)
}
.info-titulo{
    /* border: 1px solid black; */
    width: 120px;
    padding-left: 8px;
}
.info-texto{
    /* border: 1px solid black; */
    width: 320px;
    border-radius: 3px;
    font-size: 20px;
    background: var(--quaseBranco);
    padding: 5px;
}
.info-input-dis {
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 4px;
    opacity:80%
}
.info-input{
    border: none;
}
.container-info input{
    width: 320px;
    border-radius: 3px;
    font-size: 20px;
    background: var(--quaseBranco);
    padding: 5px;
}
.icons-info:hover{
    cursor: pointer;
    margin-left: 5px;
}
.icons-info{
    margin-left: 5px;
}
.icons-casos:hover{
    cursor: pointer;
}

